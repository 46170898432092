





























































































import { Component, Prop, Watch } from 'vue-property-decorator'
import VueBase from '@/VueBase'
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Icon,
  Breadcrumb,
} from 'view-design'
import emitter from '../taint/Emitter'
import {
  formatTimestamp,
  getAllParentNodes,
  getCurrentNode,
} from '../../utils/utils'
import { topMenu, menu, sidebarMenu } from './data'
@Component({
  name: 'layoutHeader',
  components: {
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Icon,
    Breadcrumb,
  },
})
export default class Header extends VueBase {
  @Prop() sidebarMenu: any

  private topMenu: any = topMenu
  private menu: any = menu
  private logo_en = '/upload/assets/img/logo_en.png?v=' + String(Math.random())
  private logo = '/upload/assets/img/logo.png?v=' + String(Math.random())
  changelogo() {
    this.logo_en = '/upload/assets/img/logo_en.png?v=' + String(Math.random())
    this.logo = '/upload/assets/img/logo.png?v=' + String(Math.random())
  }
  private goPath(str: string) {
    if (str) {
      window.open(str)
    }
  }
  private fmtStr(timestamp: number | any) {
    return formatTimestamp(timestamp)
  }

  get userInfo(): { role: number } {
    return this.$store.getters.userInfo
  }
  async buildIAST() {
    this.$router.push({ name: 'deploy' })
  }

  async messageList() {
    const res = await this.services.message.list({
      page_size: this.mSize,
      page: this.num_pages,
    })
  }
  private count = 0
  async messageUnreadCount() {
    if (!this.userInfo) {
      return
    }
    const res = await this.services.message.unreadCount()
    if (res.status === 201) {
      this.count = res.data.new_message_count
    } else {
      this.$message.error(res.msg)
    }
  }

  private mList = []
  private mTotal = 0
  private mLoading = false
  private num_pages = 1
  private mSize = 999
  private showPop = false
  private async deleteMessage(id: number) {
    this.$msgbox({
      showCancelButton: true,
      cancelButtonText: '取消',
      confirmButtonText: '删除',
      cancelButtonClass: 'cancelButtonClass',
      confirmButtonClass: 'delete-btn',
      showClose: false,
      dangerouslyUseHTMLString: true,
      message: `
        <div class="title">
          <i class="el-icon-warning icon"></i>
          确定删除当前消息通知?
        </div>
        <div class="tip">
          删除后不可恢复，请谨慎操作
        </div>
      `,
    }).then(async () => {
      let res
      if (id) {
        res = await this.services.message.mDelete({
          id: id,
          all: false,
        })
      } else {
        res = await this.services.message.mDelete({
          all: true,
        })
      }
      if (res.status === 201) {
        this.showMessage()
        this.messageUnreadCount()
      } else {
        this.$message.error(res.msg)
      }
      this.showPop = true
    })
  }

  mounted() {
    console.log(' ======== 监听 router ========= ')
    console.log(this.$router)
    this.$emit('update:sidebarMenu', sidebarMenu)
  }
  handleCurrentChange(val: number) {
    this.num_pages = val
    this.showMessage()
  }
  async showMessage() {
    this.mLoading = true
    const res = await this.services.message.list({
      page_size: this.mSize,
      page: this.num_pages,
    })
    await this.messageUnreadCount()
    this.mLoading = false
    if (res.status === 201) {
      this.mList = res.data.messages
      this.mTotal = res.data.page.alltotal
    } else {
      this.$message.error(res.msg)
    }
  }

  canShow(name: string) {
    return this.$store.getters.routers.includes(name)
  }

  @Watch('$route', { immediate: true })
  watchRouteSwitch() {
    this.$emit('update:sidebarMenu', sidebarMenu)
  }
  private currentRoute(item: any) {
    console.log('this.$route.matched', this.$route)
    let flag = false
    let { name } = item
    if (this.$route.name === name) {
      flag = true
    } else if (sidebarMenu.length > 0) {
      let arr = sidebarMenu
        .map((item: any) => {
          let temp: any = []
          this._flat(temp, item)
          return temp
        })
        .flat()
      flag = arr.some((ele: any) => {
        return this.$route.matched.some((r) => r.name === ele.name)
      })
    }
    // if (flag) this.$emit('update:sidebarMenu', item.sidebarMenu)
    if (flag) {
      console.log('==================================')
      console.log(sidebarMenu)
      this.$emit('update:sidebarMenu', sidebarMenu)
    }
    // this.$emit('update:sidebarMenu', sidebarMenu)
    return flag
  }

  _flat(target: any, item: any) {
    target.push(item)
    if (item.children && item.children.length > 0) {
      item.children.forEach((ele: any) => {
        this._flat(target, ele)
      })
    }
  }

  private async logOut() {
    await this.$store.dispatch('user/logOut')
  }
  private timer: any = null

  get sca_setup(): any {
    return this.$store.state.user.userInfo?.sca_setup
  }

  created() {
    this.messageUnreadCount()
    this.timer = setInterval(() => {
      this.messageUnreadCount()
    }, 60 * 1000)
    emitter.on('changelogo', this.changelogo)
  }

  beforeDestroy() {
    clearInterval(this.timer)
  }

  handleClick(item: any) {
    item.name == 'knowledge'
      ? this.$router.push('/knowledge/knowledge')
      : this.$router.push({ name: item.name })
  }

  handleClickSetting(item: any) {
    this.$emit('update:sidebarMenu', [])
    this.$router.push({ name: item.name })
  }

  get rightsRouters() {
    return this.$store.getters.rightsRouters || []
  }

  get pageBreadcrumb() {
    const curPathName = this.$route.name
    const allParentMenus = getAllParentNodes(sidebarMenu, curPathName)
    const curMenu = getCurrentNode(sidebarMenu, curPathName)
    const allMenus: Array<any> = []
    if (allParentMenus && allParentMenus.length > 0) {
      for (let i = 0; i < allParentMenus.length; i++) {
        allMenus.unshift(allParentMenus[i])
      }
      allMenus.push(curMenu)
    } else {
      if (curMenu) {
        allMenus.push(curMenu)
      }
    }
    console.log(" ==== all  menus =====  ")
    console.log(allParentMenus)
    console.log(allMenus)
    return allMenus
    // const routePathes = this.$route.matched
    // return routePathes.slice(1, routePathes.length)
  }
}
